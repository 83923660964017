import { createSelector } from '@ngrx/store';
import { getControlCentreState } from '..';
import { enumConverter } from '../../../../../components/shared/control-centre-topmenu/control-centre-topmenu.component';

export const getCollaborationsState = createSelector(
  getControlCentreState,
  (state) => state.collaboration,
);

export const getCollaboration = createSelector(
  getCollaborationsState,
  (state) => state.collaboration,
);

export const getCollaborationCustodians = createSelector(
  getCollaboration,
  (collaboration) => (collaboration ? collaboration.members : []),
);

export const getCollaborationCustodianNameById = (custodianId: number) => createSelector(
  getCollaborationCustodians,
  (members) => { 
    return members ? members.find(m => m.collaboratorId === custodianId).collaboratorName : null;},
);

export const getCollaborationLoading = createSelector(
  getCollaborationsState,
  (state) => state.loadingCollaboration,
);

export const getIsCoordinator = createSelector(
  getCollaborationsState,
  (state) => state.collaboration?.isCoordinator,
);

export const getCoordinatorId = createSelector(
  getCollaborationsState,
  (state) => state.collaboration?.coordinatorId,
);

export const getCollaborationSaving = createSelector(
  getCollaborationsState,
  (state) => state.savingCollaboration,
);

export const getCollaborationHasBeenRun = createSelector(
  getCollaborationsState,
  (state) => state.collaboration.lastRunDate,
);

export const isDataAvailable = createSelector(
  getCollaborationsState,
  (state) => state.collaboration.dataAvailable,
);

export const getUpdatingMembers = createSelector(
  getCollaborationsState,
  (state) => state.updatingMembers,
);

export const getCanvasIsLocked = createSelector(
  getCollaborationsState,
  (state) =>
    state.collaboration
      ? state.collaboration.canvasStatus !== 'notapproved'
      : true,
);

export const getNewCollaborationId = createSelector(
  getCollaborationsState,
  (state) => {
    return state.collaborationCanvas
      ? state.collaborationCanvas.newCollaborationCanvasId
      : false;
  },
);

export const getCanvasIsApproved = createSelector(
  getCollaborationsState,
  (state) =>
    state.collaboration
      ? state.collaboration.canvasStatus === 'approved'
      : false,
);

export const isSecureCloudTransfer = createSelector(
  getCollaborationsState,
  (state) =>
    state.collaboration
      ? String(state.collaboration.collaborationType) === enumConverter()
      : false,
);

export const getMemberModelByCustodianId = (dataCustodianId: number) =>
  createSelector(getCollaboration, (state) =>
    state
      ? state.members.find((m) => m.collaboratorId === dataCustodianId)
      : null,
  );

export const getMemberCreditUsageThreshold = (dataCustodianId: number) =>
  createSelector(getMemberModelByCustodianId(dataCustodianId), (state) => {
    return state ? state.creditUsageThreshold : 0;
  });

export const getMemberCredits = (dataCustodianId: number) =>
  createSelector(getMemberModelByCustodianId(dataCustodianId), (state) => {
    return state ? state.creditUsageThreshold : 0;
  });
