export interface CanvasActionChart {
  collaborationId: number;
  actionGroupId: string;
  chartType: ChartType;
  tableName: string;
  chartTitle: string;
  chartDescription: string;
  chartDetails: ChartDetails;
  chartGroup: string;
  basicGraphData?: BasicGraphDto[];
  timeSeriesGraphData?: Record<string, TimeSeriesDto[]>;
  nestedPieChartData?: NestedPieChart[];
  stackedChartData?: StackedChart;
  vennGraphData?: VennChart[];
  timeSeries?: TimeLineSeries[];
  geoMapData?: MapObject[];
  loading?: boolean;
}

export enum ChartType {
  NestedDonut = 'NestedDonut',
  Venn = 'Venn',
  Column = 'Column',
  Clustered = 'Clustered',
  Circles = 'Circles',
  TimeSeries = 'TimeSeries',
  MapBubbles = 'MapBubbles',
  Geo = 'Geo',
}

export interface ChartDetails {
  data: Record<string, DataPointModel>;
  clusteredChartType: number;
  blankData: boolean;
  blankDataLabel: string;
  geoDataType: string;
  geoDataFilter: string[];
}

export interface BasicGraphDto {
  name: string;
  value: number;
}

export interface DataPointModel {
  tableName: string;
  columnName: number;
}

export interface NestedPieChart {
  outerName: number;
  name: string;
  inner: number;
  outer: number;
}

export interface VennChart extends BasicGraphDto {
  sets: string[];
}

export interface StackedChart {
  values: object[];
  names: string[];
}

export interface TimeLineSeries {
  date: Date;
  value: number;
}

export interface TimeSeriesDto extends BasicGraphDto {
  date: Date;
}

export interface MapObject {
  type: string;
  properties: Properties;
  geometry: Geometry;
}

export interface Properties {
  code: string;
  name: string;
  value: number;
}

export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface Marker{
  location: GeoMarker;
  title: string;
  weight: number;
}

export interface HeatMarker{
  location: google.maps.LatLng;
  weight: number;
}
export interface GeoMarker {
  lat: number;
  lng: number;
}
